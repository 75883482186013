.button-minimal {
    font-family: inherit;
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    cursor: none;
    transition: background-color 0.3s, color 0.3s;
    position: relative;
}

.button-minimal:hover {
    background-color: #ffffff;
    color: rgb(119, 149, 189);
}