.cloud-container {
    position: absolute; 
    bottom: 20vh;
    overflow: hidden;
    width: 100%;
    height: 150vh;
    z-index: 0;
}

.sliding-cloud1 {
    position: absolute;
    bottom: 75vh;
    left: -35vw;
    width: 40vw;
    transition: transform 3s ease-out;
    opacity: 15%;
}

.sliding-cloud1.visible {
    transform: translateX(20vw);
}

.sliding-cloud2 {
    position: absolute;
    bottom: 50vh;
    right: -40vw; 
    width: 45vw;
    transition: transform 3s ease-out;
    opacity: 50%;
}

.sliding-cloud2.visible {
    transform: translateX(-20vw);
}

.sliding-cloud3 {
    position: absolute;
    bottom: 35vh;
    left: -40vw;
    width: 30vw;
    transition: transform 3s ease-out;
    opacity: 80%;
}

.sliding-cloud3.visible {
    transform: translateX(34vw);
}

.sliding-cloud4 {
    position: absolute;
    bottom: 10vh;
    right: -40vw; 
    width: 30vw;
    transition: transform 3s ease-out;
    opacity: 100%;
}

.sliding-cloud4.visible {
    transform: translateX(-40vw);
}

.orbit-container {
    position: absolute; 
    top: 0vh;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    z-index: 0;
}

.orbit {
    position: absolute;
    top: 10vh;
    left: -10vw;
    width: 40vw;
    transition: transform 3s ease-out;
    opacity: 8%;
    z-index: 0;
}

.orbit.hidden {
    transform: translateX(-10vw);
}
 
@media (max-width: 768px) {
    .sliding-cloud4 {
        width: 40vw;
    }

    .orbit {
        position: absolute;
        top: 10vh;
        left: -15vw;
        width: 60vw;
        transition: transform 3s ease-out;
        opacity: 8%;
        z-index: 0;
    }

}