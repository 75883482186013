.stars-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
}

.star {
    position: absolute;
    /* width: 2px;
    height: 2px;
    background: white; */
    font-size: .6rem;
    border-radius: 50%;
    opacity: 0;
    animation: twinkle 1.5s infinite ease-in-out;
}

@keyframes twinkle {

    0%,
    100% {
        opacity: 0;
        transform: scale(1);
    }

    50% {
        opacity: .5;
        transform: scale(1.5);
    }
}