.footer-container {
    position: absolute;
    height: 450px;
    overflow: hidden;
    bottom: 0vh;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}
  
.cactus-1 {
    position: absolute;
    bottom: 3vh; 
    right: -40px;
    height: 50vh;
    z-index: 1; 
}

.cactus-2 {
    position: absolute;
    bottom: 6vh; 
    left: 30px;
    height: 30vh;
    z-index: 1; 
}

.desert-footer {
    background-color: #d8bc8c;
    color: #fff;
    padding: 0px;
    text-align: center;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 120px;
    z-index: 0;
    bottom: 0px
}

@media (max-width: 768px) {
    .cactus-1 {
        right: -15vw;
        height: 40vh;
    }
    
    .cactus-2 {
        left: 0vw;
        height: 20vh;
    }
}